import React from 'react';

export const Stars = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 600 600"
		width="600"
		height="600"
		className="stars"
	>
		<title>Griffen Schwiesow stars logo</title>
		<g fill="#1467ff" fillRule="evenodd" stroke="#000" strokeWidth="12">
			<path d="M569.533 253.827L503.945 175l65.588-78.827-101.06 17.387L433 17.345l-35.472 96.215-101.061-17.387L362.055 175l-65.588 78.827 101.06-17.387L433 332.655l35.472-96.215 101.061 17.387z" />
			<path d="M303.533 317.827L237.945 239l65.588-78.827-101.06 17.387L167 81.345l-35.472 96.215-101.061-17.387L96.055 239l-65.588 78.827 101.06-17.387L167 396.655l35.472-96.215 101.061 17.387z" />
			<path d="M485.533 503.827L419.945 425l65.588-78.827-101.06 17.387L349 267.345l-35.472 96.215-101.061-17.387L278.055 425l-65.588 78.827 101.06-17.387L349 582.655l35.472-96.215 101.061 17.387z" />
		</g>
	</svg>
);

export const Close = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fillRule="evenodd"
		clipRule="evenodd"
		className="icon"
	>
		<path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5 15.538l-3.592-3.548 3.546-3.587-1.416-1.403-3.545 3.589-3.588-3.543-1.405 1.405 3.593 3.552-3.547 3.592 1.405 1.405 3.555-3.596 3.591 3.55 1.403-1.416z" />
	</svg>
);
