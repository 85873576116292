module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://access.griffen.design","disableCookies":true,"siteUrl":"https://www.griffen.design","requireConsent":true,"dev":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.griffen.design","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Griffen Schwiesow","short_name":"Griffen","description":"Fine, fresh, fiernce. A modern portfolio.","start_url":"/","background_color":"#fff8dc","theme_color":"#1467ff","display":"standalone","icon":"src/graphics/icon.png","localize":[{"start_url":"/de/","lang":"de","name":"Griffen Schwiesow","short_name":"Griffen","description":"Hübsch, frech, leidenschaftlich. Ein modernes Portfolio."},{"start_url":"/ja/","lang":"ja","name":"グリフィン・シュヴィーゾー","short_name":"グリフィン","description":"見た目も良くて激しくてモダーンのポートフォリオ"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"24af7d4cbd76f864e8e0b747cb593a55"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
